// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtyaqvvesselpe
import Pui9HttpRequests from '@Pui9Requests';

export async function disableVesselPowerRatios(vesselpowerratio) {
	const controller = '/aqvvesselpowerratio/disableVesselPowerRatio';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		vesselpowerratio,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableVesselPowerRatios(vesselpowerratio) {
	const controller = '/aqvvesselpowerratio/enableVesselPowerRatio';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		vesselpowerratio,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
